<template>
  <div class="container">
    <h1 class="text-center text--blue">Discover Snap Locker</h1>
    <h2 class="text-center">Securely Store Your Memories</h2>
    <p class="mt-4 text-center">
      Snap Locker is your go-to mobile app for preserving precious memories.
      Seamlessly manage your photo collection and ensure your cherished moments
      are always at your fingertips.
    </p>
    <div class="row">
      <!-- First column -->
      <div class="col-md-6">
        <div class="d-flex">
          <ul>
            <li>Effortless Organization:</li>
            <li>
              Experience seamless organization of your photo collection, making
              it easy to locate and relive your favorite moments.
            </li>
          </ul>
        </div>
      </div>

      <!-- Second column -->
      <div class="col-md-6">
        <div class="d-flex">
          <ul>
            <li>Personalized Memories:</li>
            <li>
              Customize your experience by uploading and personalizing your
              images, ensuring each memory reflects your unique story.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Third column -->
      <div class="col-md-6">
        <div class="d-flex">
          <ul>
            <li>Event Reminders:</li>
            <li>
              Never miss a photo-worthy moment by setting reminders for special
              events, ensuring you capture and cherish every important occasion.
            </li>
          </ul>
        </div>
      </div>

      <!-- Fourth column -->
      <div class="col-md-6">
        <div class="d-flex">
          <ul>
            <li>Multi-Album Management:</li>
            <li>
              Effortlessly manage multiple albums, providing a convenient way to
              organize and categorize your photos based on various themes or
              occasions.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex centered">
      <a class="app-button" href="https://apps.apple.com/app/6474502974">
        <img src="../assets/apple.png" alt="App Store" />
        <div class="text-container">
          <div>Get the App on</div>
          <div class="store__text">App Store</div>
        </div>
      </a>
    </div>
    <p class="text-center mt-4">
      Experience the joy of reliving your best moments. Snap Locker is available
      now on the App Store.
    </p>
  </div>
</template>

<style lang="scss">
@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem !important;
  }
}

h2 {
  font-size: 1.5rem !important;
  margin-bottom: 1rem;
}

a {
  text-decoration: none !important;
}

.app-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #1f1f1f; /* Attractive dark color */
  transition: transform 1s ease-in-out;
  margin: 20px 0 40px 0;

  img {
    height: 35px;
    width: 35px;
  }

  .text-container {
    margin: 0 10px;

    div {
      color: white;
      font-size: small;
      text-decoration: none;
      text-align: center;
    }
    .store__text {
      font-size: medium;
      font-weight: 600;
    }
  }
}

.app-button:hover {
  transform: translateY(-5px);
}
</style>
